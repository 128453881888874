<template>
	<div class="person_page" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="bg_white">
			<el-row class="p2 c_gray" type="flex" align="middle" style="height:50px;">
				<el-col :span="4">
					<span></span>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('会员中心')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>

		<!-- verify basic -->
		<div class="p-14 mt3 bg_white f13" @click="basic_verify()">
			<el-row>
				<el-col :span="12" class="tl">
					{{$t("初级认证")}}
				</el-col>
				<el-col :span="12" class="tr">
					<div v-if="verify_basic==0" class="c_down">
						<i class="fa fa-close" style="margin-right:5px;"></i>
						<span>{{$t('未认证')}}</span>
					</div>

					<div v-if="verify_basic==1" class="c_blue">
						<i class="fa fa-check" style="margin-right:5px;"></i>
						<span>{{$t('待审核')}}</span>
					</div>

					<div v-if="verify_basic==2" class="c_blue">
						<i class="fa fa-check" style="margin-right:5px;"></i>
						<span>{{$t('已认证')}}</span>
					</div>

					<div v-if="verify_basic==3" class="c_down">
						<i class="fa fa-close" style="margin-right:5px;"></i>
						<span>{{$t('认证失败')}}</span>
					</div>
				</el-col>
			</el-row>
		</div>

		<!-- verify advance -->
		<div class="p-14 mt3 bg_white f13" v-if="verify_basic==2" @click="advance_verify()">
			<el-row>
				<el-col :span="12" class="tl">
					{{$t("高级认证")}}
				</el-col>
				<el-col :span="12" class="tr">
					<div v-if="verify_advance==0" class="c_up">
						<i class="fa fa-close" style="margin-right:5px;"></i>
						<span>{{$t('未认证')}}</span>
					</div>

					<div v-if="verify_advance==1" class="c_blue">
						<i class="fa fa-check" style="margin-right:5px;"></i>
						<span>{{$t('待审核')}}</span>
					</div>

					<div v-if="verify_advance==2" class="c_blue">
						<i class="fa fa-check" style="margin-right:5px;"></i>
						<span>{{$t('已认证')}}</span>
					</div>

					<div v-if="verify_advance==3" class="c_down">
						<i class="fa fa-close" style="margin-right:5px;"></i>
						<span>{{$t('认证失败')}}</span>
					</div>
				</el-col>
			</el-row>
		</div>

		<!-- basic info -->
		<div class="p-14 mt3 bg_white f13">
			<el-row>
				<el-col :span="6" class="tl c_gray">{{$t('姓名')}}</el-col>
				<el-col :span="18" class="tr">{{userinfo.realname}}</el-col>
			</el-row>

			<el-row class="mt4">
				<el-col :span="6" class="tl c_gray">{{$t('证件号码')}}</el-col>
				<el-col :span="18" class="tr">{{userinfo.idcard_num}}</el-col>
			</el-row>

			<el-row class="mt4">
				<el-col :span="6" class="tl c_gray">{{$t('国家地区')}}</el-col>
				<el-col :span="18" class="tr">{{userinfo.country}}</el-col>
			</el-row>

			<el-row class="mt4">
				<el-col :span="6" class="tl c_gray">{{$t('地址')}}</el-col>
				<el-col :span="18" class="tr">{{userinfo.address}}</el-col>
			</el-row>
		</div>
		
		<!--infos -->
		<div class="p-14 mt3 bg_white f13 c_black2 tl" style="line-height:20px;">
			<p>According to different regulations in different jurisdictions and different types of entities, the content of your information we collect may be inconsistent. In principle, the following information will be collected from registered individuals:</p>
			
			<p>(a) Basic personal information: your name, address (and permanent address, if different), date of birth and nationality, etc. if available. Identity verification should be based on official or other similar authority issued documents such as passports, identity cards or other identification documents required and elicited by different jurisdictions.</p>
			
			<p>(b) Valid photo: You must provide a photo of you with your ID on your chest before you register; and</p>
			
			<p>(c) Contact details: telephone/mobile number and/or valid email address.</p>
		</div>


		<!-- draws -->
		<el-drawer :title="$t('初级认证')" :visible.sync="showBasicVerify" direction="ttb" size="100%">
			<div class="m5">
				<div class="pb4" @click="showCountryList=true">
					<el-input v-model="basic.country" :placeholder="$t('国家地区')" prefix-icon="fa fa-globe" disabled
						suffix-icon="fa fa-angle-down"></el-input>
				</div>

				<div class="pb4">
					<el-input v-model="basic.realname" :placeholder="$t('姓名')" prefix-icon="fa fa-user" ref="realname">
					</el-input>
				</div>

				<div class="pb4">
					<el-input v-model="basic.idcard_num" :placeholder="$t('证件号码')" prefix-icon="fa fa-id-card"
						ref="idcard_num">
					</el-input>
				</div>

				<div class="pb4">
					<el-input v-model="basic.address" :placeholder="$t('地址')" prefix-icon="fa fa-map-marker"
						ref="address"></el-input>
				</div>

				<div class="w100">
					<el-button type="primary" round class="w80" @click="submitBasic()">{{$t('完成认证')}}</el-button>
				</div>
			</div>
		</el-drawer>

		<!-- country list -->
		<el-drawer :title="$t('国家地区')" :visible.sync="showCountryList" direction="ttb" size="70%">
			<div class="pl4 pr4 pt2">
				<el-input v-model="search_country" prefix-icon="fa fa-search"></el-input>
			</div>
			<div class="pt2">
				<div class="tl m4" v-for="item of areacodeList" v-bind:key="item.id" @click="choiseCountry(item.title)">
					{{item.title}}
				</div>
			</div>
		</el-drawer>

		<!-- 高级认证 -->
		<el-drawer :title="$t('高级认证')" :visible.sync="showAdvanceVerify" direction="ttb" size="100%">
			<div class="pt3">
				<div class="bg_body m4 bradius8" @click="callUpload('cardzm')">
					<div v-if="cardzm==false">
						<div class="pt5">
							<i class="fa fa-camera c_gray upload_icon"></i>
						</div>
						<div class="mt3 pb5 c_black2">{{$t('请上传身份证正面')}}</div>
					</div>

					<div v-if="cardzm!=false">
						<img :src="cardzm" class="w100" alt="">
					</div>
				</div>
			</div>
			
			<div class="">
				<div class="bg_body m4 bradius8" @click="callUpload('cardfm')">
					<div v-if="cardfm==false">
						<div class="pt5">
							<i class="fa fa-camera c_gray upload_icon"></i>
						</div>
						<div class="mt3 pb5 c_black2">{{$t('请上传身份证反面')}}</div>
					</div>
			
					<div v-if="cardfm!=false">
						<img :src="cardfm" class="w100" alt="">
					</div>
				</div>
			</div>
			
			<div class="">
				<div class="bg_body m4 bradius8" @click="callUpload('cardtake')">
					<div v-if="cardtake==false">
						<div class="pt5">
							<i class="fa fa-camera c_gray upload_icon"></i>
						</div>
						<div class="mt3 pb5 c_black2">{{$t('请上传手持身份证照片')}}</div>
					</div>
			
					<div v-if="cardtake!=false">
						<img :src="cardtake" class="w100" alt="">
					</div>
				</div>
			</div>

			<div class="w100 pb5">
				<el-button class="w80" type="primary" round @click="submitAdvance()">{{$t('完成认证')}}</el-button>
			</div>

			<input type="file" ref="cardzm" style="display: none;" @change="uploadFile('cardzm')">
			<input type="file" ref="cardfm" style="display: none;" @change="uploadFile('cardfm')">
			<input type="file" ref="cardtake" style="display: none;" @change="uploadFile('cardtake')">
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: "PersonalView",
		data() {
			return {
				loading: false,
				verify_basic: -1,
				verify_advance: -1,
				userinfo: false,
				basic: {
					country: "",
					realname: "",
					address: "",
					idcard_num: "",
				},
				showBasicVerify: false,
				showAdvanceVerify: false,

				showCountryList: false,
				areacodes: [],

				// upload
				cardzm: false,
				cardfm: false,
				cardtake: false,
				advance: {
					cardzm: "",
					cardfm: "",
					cardtake: "",
				},

				// search
				search_country: "",
			}
		},
		computed: {
			areacodeList: function(){
				let that = this;
				return this.areacodes.filter(function(item){
					if( item.title.toLowerCase().indexOf(that.search_country.toLowerCase()) >= 0 ){
						return true;
					}
					return false;
				})
			}
		},
		watch: {
			showCountryList: function(newV, oldV){
				if( newV ){
					this.search_country = "";
				}
				console.log(oldV)
			}
		},
		methods: {
			basic_verify() {
				if (this.verify_basic != 0 && this.verify_basic != 3) {
					return false;
				}

				// show 
				this.showBasicVerify = true;
			},

			advance_verify() {
				if (this.verify_advance != 0 && this.verify_advance != 3) {
					return false;
				}

				// show 
				this.showAdvanceVerify = true;
			},

			choiseCountry(country) {
				this.basic.country = country;
				this.showCountryList = false;
			},

			// submit basic
			submitBasic() {
				if (!this.basic.country) {
					this.showCountryList = true;
					return
				}
				if (!this.basic.realname) {
					this.$refs.realname.focus();
					return
				}
				if (!this.basic.idcard_num) {
					this.$refs.idcard_num.focus();
					return
				}
				if (!this.basic.address) {
					this.$refs.address.focus();
					return
				}

				// submit
				// show loading
				this.loading = true;
				let that = this;
				this.greq('post', '/api/basic_verify', that.basic, function(rs) {
					that.loading = false;
					if (rs.status) {
						// close drawer
						that.showBasicVerify = false;

						// update userinfo
						that.userinfo = rs.data;
						that.verify_basic = rs.data.basic_verify_status;
					} else {
						that.$message({
							message: rs.msg,
							type: 'error'
						})
					}
				}, function(err) {
					console.log(err)
					that.loading = false;
				})
			},
			
			// 提交高级认证
			submitAdvance(){
				if( !this.advance.cardzm ){
					this.$message({message:this.$t('请上传身份证正面'),type:'error'})
					return
				}
				if( !this.advance.cardfm ){
					this.$message({message:this.$t('请上传身份证反面'),type:'error'})
					return
				}
				if( !this.advance.cardtake ){
					this.$message({message:this.$t('请上传手持身份证照片'),type:'error'})
					return
				}
				
				let that = this;
				this.greq('post', '/api/advance_verify', this.advance, function(rs){
					if( rs.status ){
						// close draw and clear data
						that.advance = {
							cardzm: "",
							cardfm: "",
							cardtake: "",
						}
						that.showAdvanceVerify = false;
						
						// update userinfo
						that.userinfo = rs.data
						that.verify_advance = rs.data.rzstatus;
					} else {
						that.gerror(rs.msg)
					}
				});
			},

			// upload
			callUpload(type) {
				console.log(type);
				this.$refs[type].click();
			},

			// upload file
			uploadFile(type) {
				// console.log(this.$refs.cardzm.files[0])
				let that = this;
				let fname = this.$refs[type].value;
				if (!fname.match(/\.(png|jpg|jpeg|gif)$/i)) {
					// layer.msg("{:L('Only support jpg/png')}");
					this.$message({
						message: this.$t("仅支持JPG/PNG格式文件"),
						type: 'error'
					})
					return false;
				}

				var fileObj = this.$refs[type].files[0];
				var reader = new FileReader();

				reader.readAsArrayBuffer(fileObj);
				reader.onload = function(ev) {
					var blob = new Blob([ev.target['result']]);
					window['URL'] = window['URL'] || window['webkitURL'];
					var blobURL = window['URL'].createObjectURL(blob);
					var image = new Image();
					image.src = blobURL;
					image.onload = (e) => {
						console.log(e)
						var thumb = that.resizeMe(image, 800, 0); //将图片转换成了base64
						
						// show loading
						
						that.greq('post', '/api/stream_upload', thumb, function(rs){
							if(rs.status){
								that[type] = rs.data.src;
								that.advance[type] = rs.data.fname;
							}
						});
					}
				}
			},

			// 压缩图片
			resizeMe(img, max_width, max_height) {
				var canvas = document.createElement('canvas');
				var width = img.width;
				var height = img.height;

				// 在这里图片是等比例缩放的，调用方法时填入图片允许的最大宽度或者是最大的高度
				//如果最大宽度为0 则按照最大高度固定，宽度自适应的方式来实现
				//如果是最大高度为0，则按照最大的宽度来实现
				if (max_width == 0) {
					if (height > max_height) {
						width = Math.round(width *= max_height / height);
						height = max_height;
					}
				}
				if (max_height == 0) {
					if (width > max_width) {
						height = Math.round(height *= max_width / width);
						width = max_width;
					}
				}
				canvas.width = width;
				canvas.height = height;
				var ctx = canvas.getContext("2d");
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);
				return canvas.toDataURL("image/jpeg", 0.5); //这里的0.7值的是图片的质量

			}
		},
		mounted() {
			// get userinfo
			let that = this;
			this.greq('get', '/api/userinfo', false, function(rs) {
				if (rs.status) {
					if (rs.data.basic_verify_status > 0) {
						that.userinfo = rs.data;
					}
					that.verify_basic = rs.data.basic_verify_status;
					that.verify_advance = rs.data.rzstatus;
				}
			})


			// get countrys
			let langs = {
				zh: 1,
				en: 2,
				tw: 3,
				th: 4,
				jp: 5,
				es: 6,
				fr: 7,
				de: 8,
				ko: 9,
				vi: 10,
				pt: 11,
			}
			let lang = this.glang()
			let langIndex = langs[lang]
			let areacodes = require('../json/areacodes.json')
			let newAreacodes = [];
			for (let row of areacodes) {
				newAreacodes.push({
					code: row[0],
					title: row[langIndex]
				})
			}
			console.log(newAreacodes)
			this.areacodes = newAreacodes;
		}
	}
</script>

<style>
	.upload_icon {
		display: block;width:80px;height:80px;margin:0 auto;background-color:#fff;border-radius: 40px;line-height:80px;font-size: 40px;
	}
</style>
