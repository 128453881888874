<template>
	<div>redirecting...</div>
</template>

<script>
	export default {
		name: "ToView",
		props: ['type', 'id'],
		beforeMount(){
			let url = '';
			if( this.type == 'trade' ){
				url = '/'+this.type+'/'+this.id+'/contract'
			} else {
				url = '/'+this.type+'/'+this.id
			}
			this.$router.push(url);
		}
	}
</script>

<style>
</style>