<template>
	<transition name="fade">
		<div class="s_container">
			<el-row>
				<el-col :span=20>
					<el-input class="h_search" size="small" :placeholder="this.$t('搜索')" prefix-icon="el-icon-search"
						v-model="input">
					</el-input>
				</el-col>
				<el-col :span=4>
					<el-button type="text" class="c_black" @click="back()">{{$t('取消')}}</el-button>
				</el-col>
			</el-row>
			
			<div class="s_result">
				<div v-if="items.length" class="s_result_container">
					<div class="s_tag">{{ $t("搜索结果") }}</div>
					
					<div class="s_item" v-for="row of items" v-bind:key="row.id" @click="trade(row.id)">
						<div>{{ row.title }}</div>
					</div>
				</div>
			</div>
		</div>
		
	</transition>
</template>

<script>
	export default {
		name: 'SearchPage',
		methods: {
			back: function() {
				this.$router.push('/');
			},

			trade: function(id) {
				this.$router.push('/trade/'+id+'/contract');
			}
		},
		data() {
			return {
				input: "",
				search_text: this.$t("m.search_text"),
				items: [],
				remote_items: [],
			}
		},
		watch: {
			input: function(val){
				val = val.trim()
				if( !val ){
					this.items = [];
					return
				}
				this.items = [];
				for(let i in this.remote_items){
					if(this.remote_items[i]['title'].toLowerCase().indexOf(val.toLowerCase()) >= 0 ){
						this.items.push(this.remote_items[i]);
					}
				}
			}
		},
		mounted() {
			// var url = this.GAPI + '/ajax/test';
			// this.$axios.get(url).then(rs=>{
			// 	console.log(rs.data)
			// 	// for(let i in rs.data.data){
			// 	// 	console.log(rs.data.data[i]);
			// 	// 	this.remote_items.push(rs.data.data[i])
			// 	// }
			// 	this.remote_items = rs.data.data;
			// }).catch(function(rs){console.log(rs)})
			let that = this;
			this.greq('get', '/main/product_list', false, function(rs){
				if( rs.status ){
					that.remote_items = rs.data;
				}
			})
			
			
			// hide footer
			console.log('get ref')
			console.log(this.$parent.$refs.footer)
			// this.$parent.$refs.footer
			this.$parent.show_footer = false;
		},
		beforeDestroy() {
			// show footer
			this.$parent.show_footer = true;
		}
	}
</script>

<style>

</style>
