<template>
	<div class="earn_page" v-loading.fullscreen.lock="" element-loading-background="rgba(0,0,0,0.4)">
		<div class="bg_white borderb">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/asset" class="c_black">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('理财')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>

        <!--total info-->
        <div class="p5 bg_white borderb">
            <el-row type="flex" align="middle" class="tl">
                <el-col :span="12">
                    <div>{{$t('总收益')}}</div>
                    <div class="f24 mt2"><span class="fbold">{{total_reward}}</span> <span class="c_gray f14">USDT</span></div>
                </el-col>
                <el-col :span="12">
                </el-col>
            </el-row>
        </div>

        <!-- tab -->
        <div class="pl5 pt2 bg_white borderb">
            <div class="p2 float_item float_item_active" v-if="tab==0">{{$t('IBKR基金')}}</div>
			<div class="p2 float_item" v-if="tab==1" @click="tab=0">{{$t('IBKR基金')}}</div>

			<div class="p2 float_item float_item_active" style="margin-left: 20px;" v-if="tab==1">{{$t('订单')}}</div>
            <div class="p2 float_item" style="margin-left: 20px;" v-if="tab==0" @click="tab=1">{{$t('订单')}}</div>
            <div style="clear: both;"></div>
        </div>

        <!--content of project-->
		<div v-if="tab==0">
			<div class="p3 bg_white borderb" v-for="item of earns" v-bind:key="item.id">
				<el-row type="flex" align="middle">
					<el-col :span="4">
						<img src="../assets/avatar.png" width="40px" style="border-radius: 8px;" alt="">
					</el-col>
					<el-col :span="12" class="tl">
						<span class="f14">{{$t('定期理财')}}</span>
					</el-col>
					<el-col :span="8" class="tr">
						<el-button type="primary" size="mini" @click="buy(item.id)">{{$t('认购')}}</el-button>
					</el-col>
				</el-row>
				
				<el-row class="mt3">
					<el-col :span="6">
						<div class="c_gray f12">{{$t('收益率')}}</div>
						<div class="mt2 c_blue fbold">
							<span class="bg_half">{{item.ror}}%</span>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="c_gray f12">{{$t('起投数量')}}</div>
						<div class="mt2 c_blue">{{item.min}}</div>
					</el-col>
					<el-col :span="6">
						<div class="c_gray f12">{{$t('周期')}}</div>
						<div class="mt2 c_blue">{{item.cycle}} {{$t('天')}}</div>
					</el-col>
				</el-row>
			</div>
		</div>

		<!--content of orders-->
		<div v-if="tab==1">
			<div :class="{'pt3 pb3 pl5 pr5 borderb': true, 'bg_white':item.status==0}" v-for="item of orders" v-bind:key="item.id">
				<el-row type="flex" align="middle">
					<el-col :span="12" class="tl">
						<span class="c_up"></span>
						<span :class="{'fbold':true, 'bg_half':item.status==0}">USDT</span>
					</el-col>
					<el-col :span="12" class="tr">
						<span class="c_gray">{{item.start_day}}</span>
					</el-col>
				</el-row>

				<el-row class="pt2">
					<el-col :span="12" class="tl">
						<span class="c_gray">{{$t('数量')}}</span>
					</el-col>
					<el-col :span="12" class="tr">
						{{item.amount}}
					</el-col>
				</el-row>

				<el-row class="pt2">
					<el-col :span="12" class="tl">
						<span class="c_gray">{{$t('收益率')}}</span>
					</el-col>
					<el-col :span="12" class="tr">
						{{item.ror}}%
					</el-col>
				</el-row>

				<el-row class="pt2" v-if="item.status==0">
					<el-col :span="12" class="tl">
						<span class="c_gray">{{$t('到期时间')}}</span>
					</el-col>
					<el-col :span="12" class="tr">
						{{item.end_day}}
					</el-col>
				</el-row>

				<el-row class="pt2" v-if="item.status==1">
					<el-col :span="12" class="tl">
						<span class="c_gray">{{$t('收益')}}</span>
					</el-col>
					<el-col :span="12" class="tr">
						<span class="c_up fbold">+{{item.reward}}</span>
					</el-col>
				</el-row>
			</div>

			<div class="mt5 pb5" v-if="orders.length==0 && tab==1">
				<div><img src="../assets/nodata.png" height="50px" alt=""></div>
				<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
			</div>
		</div>
	</div>
</template>



<script>
	export default {
		name: 'EarnView',
		data(){
			return {
				tab: 0,
				earns: [],
				orders: [],
				total_reward: 0,
			}
		},
		props: {
			type: {
				type: Number,
				default: 0
			}
		},
		methods: {
			buy(id){
				this.$router.push('/earn_detail/'+id)
			}
		},
		mounted: function(){
			this.tab = this.type;
			// get earn list
			let that = this;
			this.greq('get', '/api/earns', false, function(rs){
				if( rs.status ){
					that.earns = rs.data.projects;
					that.orders = rs.data.orders;
					that.total_reward = rs.data.total_reward;
				} 
			})
		}
	}
</script>

<style>

</style>