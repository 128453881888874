<template>
	<div class="register_page">
		<div>
			<el-row class="m3">
				<el-col :span=10 style="font-size: 40px;text-indent: 10px;" class="c_blue tl">
					<router-link to="/login" class="c_blue">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span="14">&nbsp;</el-col>
			</el-row>
		</div>
		
		<div class="tl c_gray mt2">
			<div style="font-size: 30px;text-indent: 40px;">{{$t("注册")}}</div>
		</div>
		
		<el-tabs v-model="activeName" :stretch=true
			style="width:66%;margin:0 auto;padding-top:40px;" class="login_form">
			<el-tab-pane :label="$t('手机')" name="first">
				<div class="mt3">
					<div
						style="width: 1px;height: 1px;overflow: visible;z-index: 999;position: relative;top: 12px;left: 13px;">
						<span @click="drawer = true">+{{ areacode }}</span>
					</div>
					<el-input v-model="phone" :placeholder="$t('请输入手机号')" prefix-icon="el-icon-search123"></el-input>
		
					<el-input class="mt3" v-model="password" :placeholder="$t('请输入密码')" autocomplete="off" prefix-icon="fa fa-key"
						:show-password=true></el-input>
		
					<el-button type="primary" @click="submit" class="mt4 w100" round>{{$t('注册')}}</el-button>
				</div>
		
			</el-tab-pane>
		
		
			<el-tab-pane :label="$t('邮箱')" name="second" class="mt3">
				<el-input v-model="email" :placeholder="$t('请输入邮箱')" prefix-icon="fa fa-inbox"></el-input>
		
				<el-input class="mt3" v-model="password2" :placeholder="$t('请输入密码')" autocomplete="off" prefix-icon="fa fa-key"
					:show-password=true></el-input>
		
				<el-button type="primary" @click="submit" class="mt4 w100" round>{{$t('注册')}}</el-button>
			</el-tab-pane>
		</el-tabs>
	
		<div class="tc mt4 c_gray">
			{{$t('注册即同意')}} <router-link to="/content_type/2">《{{$t('用户协议')}}》</router-link>
		</div>
	
	
		<!-- 选择区号 -->
		<el-drawer :title="title" :visible.sync="drawer" :direction="direction" size="100%">
			<div class="mt2 p3">
				<el-input v-model="search" :placeholder="$t('搜索')"></el-input>
			</div>
			<div class="">
				<div class="tl m4" v-for="item of searchAreacode" v-bind:key="item.id" @click="areacode=item.code;drawer=false">
					{{item.title}} (+{{item.code}})
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: 'RegisterView',
		data(){
			return {
				search: "",
				activeName: "first",
				phone: "",
				password: "",
				email: "",
				password2: "",
				areacode: "1",
				drawer: false,
				direction: "ttb",
				title: this.$t("选择区号"),
				areacodes: [],
			}
		},
		watch: {
			drawer: function(n, o){
				if( n ){
					this.title = this.$t("选择区号");
				}
				console.log(o)
			}
		},
		computed: {
			searchAreacode: function(){
				let s = this.search.toLowerCase().trim()
				console.log(s)
				return this.areacodes.filter(function(item){
					if(item.title.toLowerCase().indexOf(s) > -1 || item.code.indexOf(s)>-1){
						return true;
					}
					return false;
				});
		
			}
		},
		methods: {
			submit: function(){
				let name = ""
				let pwd = ""
				if( this.activeName == "first" ){
					if( !this.phone || this.phone.length < 6 ){
						this.$message({message:this.$t("请填写正确的手机号"),type:'error'})
						return
					}
					if( !this.password || this.password.length < 6 ){
						this.$message({message:this.$t("请输入至少6位密码"),type:'error'})
					}
					name = "+" + this.areacode + this.phone
					pwd = this.password
				} else {
					if( !this.email ){
						this.$message({message:this.$t("请输入邮箱"),type:'error'})
						return
					}
					if( !this.password2 || this.password2.length < 6 ){
						this.$message({message:this.$t("请输入至少6位密码"),type:'error'})
					}
					name = this.email
					pwd = this.password2
				}
				
				// submit
				let that = this;
				this.greq('post', '/main/register', {name:name,pwd:pwd}, function(data){
					if( data.status ){
						that.$message(data.msg)
						
						// save token
						localStorage.setItem('token', data.data.token)
						
						// goto home
						setTimeout(function(){
							that.$router.push('/');
						}, 1000);
						
					} else {
						that.$message({message:data.msg,type:'error'})
					}
				})
			}
		},
		
		mounted() {
			this.$parent.show_footer = false;
			
			// get areacods
			let langs = {
				zh: 1,
				en: 2,
				tw: 3,
				th: 4,
				jp: 5,
				es: 6,
				fr: 7,
				de: 8,
				ko: 9,
				vi: 10,
				pt: 11
			}
			let lang = this.glang()
			let langIndex = langs[lang]
			
			let areacodes = require('../json/areacodes.json')
			let newAreacodes = [];
			for(let row of areacodes){
				newAreacodes.push({
					code: row[0],
					title: row[langIndex]
				})
			}
			this.areacodes = newAreacodes;
			
		},
		beforeDestroy() {
			this.$parent.show_footer = true;
		},
		beforeCreate() {
			if(this.glogin()){
				this.$router.push('/');
			}
		}
	}
</script>

<style>
</style>