<template>
	<div class="contract_page bg_white" v-loading.fullscreen.lock="loading" element-loading-background="rgba(0,0,0,0.4)">
		<div class="p3" style="border-bottom: 1px solid #eee;">
			<el-row class="p2 c_black2" type="flex" align="middle">
				<el-col :span="4">
					<span></span>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t('合约交易')}}
				</el-col>
				<el-col :span=4 class="">
					<span></span>
				</el-col>
			</el-row>
		</div>


		<div class="pl5 pr5 pt3 pb3">
			<el-row type="flex" align="middle" class="pt2 pb2">
				<el-col :span="12" class="tl fbold">
					<div @click="showSiderBar">
						{{info.title}} <i class="fa fa-angle-down pl3"></i>
					</div>
				</el-col>
				<el-col :span="9" class="tr">
					<img src="../assets/kline.png" width="18" alt="" @click="$router.push('/trade/'+id+'/contract')">
				</el-col>
				<el-col :span="3" class="tr">
					<i class="fa fa-info-circle f18 c_gray" @click="showinfo"></i>
				</el-col>
			</el-row>

			<div class="pt3 tl">
				<el-row>
					<el-col :span="13">
						<div class="c_gray">{{$t('净值')}}</div>
						<div>{{total}}</div>
					</el-col>
					<!-- <el-col :span="9">
						<div class="c_gray">{{$t('可用')}}</div>
						<div>23092.02</div>
					</el-col> -->
					<el-col :span="11" class="tr">
						<div class="c_gray">{{$t('风险度')}}</div>
						<div>{{risk}}%</div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div class="bg_gray bradius8 f12 p2 m2 tl">
			<i class="fa fa-volume-up"></i> {{$t('当风险过大，系统将强制对您当前合约仓位进行平仓。')}}
		</div>

		<div class="mt3">
			<el-tabs :stretch=true v-model="tab">
				<el-tab-pane :label="$t('开仓')" name="open">
					<el-row>
						<el-col :span="14">
							<div class="mt3">
								<el-row class="f12">
									<el-col :span="12">
										<div @click="order.type=0">
											<div class="short_btn m2" v-if="order.type==0">{{$t('做多')}}</div>
											<div class="empty_btn m2" v-if="order.type==1">{{$t('做多')}}</div>
										</div>
									</el-col>
									<el-col :span="12">
										<div @click="order.type=1">
											<div class="long_btn m2" v-if="order.type==1">{{$t('做空')}}</div>
											<div class="empty_btn m2" v-if="order.type==0">{{$t('做空')}}</div>
										</div>
									</el-col>
								</el-row>
							</div>

							<!-- 倍数 -->
							<div class="">
								<el-row class="f12">
									<el-col :span="12">
										<el-select v-model="order.price_type" size="mini" class="m2">
											<el-option :label="$t('市价委托')" value="0"></el-option>
											<el-option :label="$t('限价委托')" value="1"></el-option>
										</el-select>
									</el-col>
									<el-col :span="12">
										<el-select v-model="order.level" size="mini" class="m2">
											<!-- <el-option label="10X" value="10"></el-option> -->
											<el-option label="25X" value="25"></el-option>
											<el-option label="50X" value="50"></el-option>
											<el-option label="75X" value="75"></el-option>
											<el-option label="100X" value="100"></el-option>
											<!-- <el-option label="125X" value="125"></el-option>
											<el-option label="200X" value="200"></el-option> -->
										</el-select>
									</el-col>
								</el-row>
							</div>

							<!-- 限价 -->
							<div class="m2">
								<el-input size="mini" v-model="order.price" :disabled="order.price_type==0"></el-input>
							</div>

							<!-- 数量 -->
							<div class="m2">
								<el-input size="mini" v-model="order.hand" :placeholder="$t('保证金')"
									suffix-icon="hand_content c_black2"
									@input="order.hand=order.hand.replace(/[^\d]/g, '')"></el-input>
							</div>

							<!-- 止盈止损 -->
							<div class="m2 win_or_loss">
								<el-input size="mini" v-model="order.stop_win" :placeholder="$t('请输入止盈金额')"
									prefix-icon="stop_win c_black2"></el-input>
							</div>

							<div class="m2 win_or_loss">
								<el-input size="mini" v-model="order.stop_loss" :placeholder="$t('请输入止损金额')"
									prefix-icon="stop_loss c_black2"></el-input>
							</div>

							<!-- 说明 -->
							<div class="f12 tl pl2 c_black2 pb2">
								<div>{{$t('标准手价格')}} {{info.hand_price}}USDT</div>
								<div>{{$t('金额')}} {{order_amount}}USDT</div>
								<div>{{$t('手续费')}} 1USDT</div>
							</div>
							<!-- end shuoming -->

							<!-- buy button-->
							<div class="m2" @click="submit_order">
								<div class="short_btn f13" v-if="order.type==0">{{$t('做多')}}</div>
								<div class="long_btn f13" v-if="order.type==1">{{$t('做空')}}</div>
							</div>
							<!-- end buy button -->
						</el-col>


						<!-- 10档行情 -->
						<el-col :span="10">
							<div class="m3 f12">
								<el-row class="c_gray">
									<el-col :span="12" class="tl">{{$t('价格')}}(USDT)</el-col>
									<el-col :span="12" class="tr">{{$t('数量')}}</el-col>
								</el-row>


								<div class="mt2">
									<div class="mt1" v-for="item in info.level5_up" v-bind:key="item.id">
										<div class="l10_bar">
											<div class="l10_bg bg_up" :style="{width:item.w+'%'}"></div>
										</div>
										<el-row style="height:18px;line-height:18px;">
											<el-col :span="12" class="tl fbold c_up">{{item.p}}</el-col>
											<el-col :span="12" class="tr c_black2">{{item.v}}</el-col>
										</el-row>
									</div>
								</div>

								<div class="pt3 pb3 mt2 mb2 f18 fbold tl"
									style="border-top:1px solid #eee;border-bottom: 1px solid #eee;">
									<span class="c_up">{{info.price}}</span>
								</div>

								<div class="mt2">
									<div class="mt1" v-for="item in info.level5_down" v-bind:key="item.id">
										<div class="l10_bar">
											<div class="l10_bg bg_down" :style="{width:item.w+'%'}"></div>
										</div>
										<el-row style="height:18px;line-height:18px;">
											<el-col :span="12" class="tl fbold c_down">{{item.p}}</el-col>
											<el-col :span="12" class="tr c_black2">{{item.v}}</el-col>
										</el-row>
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</el-tab-pane>

				<el-tab-pane :label="$t('持仓')" name="holding">
					<div class="m5 tl">
						<div class="f14 fbold">{{$t('浮动盈亏')}}</div>
						<div class="f14 mt2 c_blue fbold">${{total_profit}}</div>
					</div>
				</el-tab-pane>
			</el-tabs>


			<!-- 持仓记录 -->
			<div class="m3">
				<div>
					<el-row type="flex" align="middle">
						<el-col :span="6">
							<div @click="record.type=0">
								<div class="bg_blue2 br6 c_white w80 pt2 pb2" v-if="record.type==0">{{$t('市价委托')}}</div>
								<div class="empty_btn w80" v-if="record.type==1">{{$t('市价委托')}}</div>
							</div>
						</el-col>
						<el-col :span="6">
							<div @click="record.type=1">
								<div class="bg_blue2 br6 c_white w80 pt2 pb2" v-if="record.type==1">{{$t('限价委托')}}</div>
								<div class="empty_btn w80" v-if="record.type==0">{{$t('限价委托')}}</div>
							</div>
						</el-col>
						<el-col :span="11" class="tr">
							<router-link class="c_blue" to="/contract_history">{{$t('全部')}}</router-link>
						</el-col>
						<el-col :span="1">
							<span></span>
						</el-col>
					</el-row>
				</div>

				<!-- 执行中的订单 -->
				<div class="mt5 pb5" v-if="orders_pending.length==0 && record.type==0">
					<div><img src="../assets/nodata.png" height="50px" alt=""></div>
					<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
				</div>

				<div class="mt3" v-if="orders_pending.length>0 && record.type==0">
					<div class="p3" v-for="item in orders_pending" v-bind:key="item.id"
						style="border-bottom: 1px solid #ddd;">
						<el-row>
							<el-col :span="3">
								<span class="bg_up p1 c_white br4 f12" v-if="item.type==0">{{$t('买')}}</span>
								<span class="bg_down p1 c_white br4 f12" v-if="item.type==1">{{$t('卖')}}</span>
							</el-col>
							<el-col :span="15" class="tl">
								{{item.pname}} x {{item.num}} {{$t('保证金')}}(USDT)
							</el-col>
							<el-col :span="6">
								<span class="c_up" v-if="item.profit>0">{{item.profit}}</span>
								<span class="c_down" v-if="item.profit<0">{{item.profit}}</span>
								<span v-if="item.profit==0">{{item.profit}}</span>
							</el-col>
						</el-row>

						<el-row type="flex" align="middle" class="f12">
							<el-col :span="9" class="tl mt2">
								<div>
									<span class="c_gray">{{$t('开仓价')}}</span>
									<span class="c_blue pl2">{{item.buyprice}}</span>
								</div>
								<div class="mt1">
									<span class="c_gray">{{$t('止盈价')}}</span>
									<span class="c_blue pl2">{{item.stop_win}}</span>
								</div>
							</el-col>
							<el-col :span="9" class="tl mt2">
								<div>
									<span class="c_gray">{{$t('当前价')}}</span>
									<span class="c_blue pl2">{{item.price}}</span>
								</div>
								<div class="mt1">
									<span class="c_gray">{{$t('止损价')}}</span>
									<span class="c_blue pl2">{{item.stop_loss}}</span>
								</div>
							</el-col>
							<el-col :span="6" class="mt2">
								<div class="bg_blue2 br6 c_white pt2 pb2 f12" @click="order_sell(item.oid)">{{$t('平仓')}}
								</div>
							</el-col>
						</el-row>
					</div>
				</div>


				<!-- 挂单 -->
				<div class="mt5 pb5" v-if="orders_wait.length==0 && record.type==1">
					<div><img src="../assets/nodata.png" height="50px" alt=""></div>
					<div class="mt2 c_gray f12">{{$t('暂无数据')}}</div>
				</div>

				<div class="mt3" v-if="orders_wait.length>0 && record.type==1">
					<div class="p3" v-for="item in orders_wait" v-bind:key="item.id"
						style="border-bottom: 1px solid #ddd;">
						<el-row>
							<el-col :span="2">
								<span class="bg_up p1 c_white br4 f12" v-if="item.type==0">{{$t('买')}}</span>
								<span class="bg_down p1 c_white br4 f12" v-if="item.type==1">{{$t('卖')}}</span>
							</el-col>
							<el-col :span="16" class="tl">
								{{item.pname}} x {{item.num}} {{$t('保证金')}}(USDT)
							</el-col>
							<el-col :span="6">
								<span></span>
							</el-col>
						</el-row>

						<el-row type="flex" align="middle" class="f12">
							<el-col :span="9" class="tl mt2">
								<div>
									<span class="c_gray">{{$t('挂单价')}}</span>
									<span class="c_blue pl2">{{item.buyprice}}</span>
								</div>
							</el-col>
							<el-col :span="9" class="tl mt2">
								<div>
									<span class="c_gray">{{$t('当前价')}}</span>
									<span class="c_blue pl2">{{item.price}}</span>
								</div>
							</el-col>
							<el-col :span="6" class="mt2">
								<div class="bg_blue2 br6 c_white pt2 pb2 f12" @click="order_cancel(item.oid)">
									{{$t('撤单')}}</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<!-- end 挂单 -->
			</div>
		</div>

		<market-sider-bar ref="sidebar" :itemCallback="sidebarCallback"></market-sider-bar>
	</div>
</template>

<script>
	import MarketSiderBar from '../components/MarketSideBar.vue'
	export default {
		name: "ContractView",
		props: {
			id: {
				type: [Number,String]
			},
			type: {
				type: [Number,String],
				default: 0
			}
		},
		components: {
			MarketSiderBar,
		},
		data() {
			return {
				loading: false,
				drawer: false,
				user: {
					profit: 0.00,
				},
				info: {
					price: 0.00,
					title: 'BTC',
					hand_price: 1,
					hand_fee: 10,
					level5_up: [],
					level5_down: [],
				},
				tab: "open",
				order: {
					type: this.type, //0 long, 1 short
					price_type: "0", // 0 市价， 1 限价
					level: "100",
					price: 0.10,
					hand: "",
					stop_win: "",
					stop_loss: "",
				},
				timer_data: false,
				timeout_get_data: 2000,
				record: {
					type: 0, // 市价
					datas: [],
				},
				total: 0, // 净值
				risk: 0, // risk
			}
		},
		computed: {
			order_amount: function() {
				return this.order.hand * this.order.level;
			},
			order_fee: function() {
				return this.order.hand * this.info.hand_fee / 100;
			},
			orders_pending: function() {
				return this.record.datas.filter(function(a) {
					return a.status == 1;
				});
			},
			orders_wait: function() {
				return this.record.datas.filter(function(a) {
					return a.status == 0;
				});
			},
			total_profit: function() {
				let profit = 0.00;
				for (let item of this.orders_pending) {
					profit = profit + parseFloat(item.profit);
				}
				return profit.toFixed(2);
			}
		},
		methods: {
			showinfo() {
				this.$alert(this.$t('合约说明双方约定在将来某个时间,以数字资产为标的,在交易所买卖标准化合约的行为。币圈投资者可以通过合约交易进行套利、套期保值、投机等行为来获取盈利。'), this.$t('合约说明'), {
					showConfirmButton: false,
					customClass: 'w80',
					callback: function(action){
						console.log(action);
					}
				});
			},
			sidebarCallback(id) {
				this.$router.push('/to/contract/' + id);
			},
			showSiderBar() {
				this.$refs.sidebar.drawer = true;
			},
			prefixSuffix() {
				document.getElementsByClassName('hand_content')[0].innerHTML = this.$t('USDT');
				document.getElementsByClassName('stop_win')[0].innerHTML = this.$t('止盈');
				document.getElementsByClassName('stop_loss')[0].innerHTML = this.$t('止损');
			},
			getData() {
				let that = this;
				this.greq('post', '/main/product_lvs', {
					pid: this.id
				}, function(rs) {
					if (rs.status) {
						let info = rs.data.info;
						that.info.title = info.title;
						that.info.price = info.price;
						that.info.hand_price = info.hand_price;
						that.info.hand_fee = info.hand_fee;
						that.info.level5_up = rs.data.lups;
						that.info.level5_down = rs.data.ldowns;

						if (that.order.price_type == 0) {
							that.order.price = info.price;
						}

						// orders
						that.record.datas = rs.data.orders;

						// get total / risk
						// status = 1 
						let total = 0;
						let risk = 0;
						for(let i=0;i<rs.data.orders.length;i++){
							let item = rs.data.orders[i]
							if( item.status == 1 ){
								total += parseFloat(item.num)+parseFloat(item.profit)
								let profit = parseFloat(item.profit)
								if( profit < 0 ){
									let tmpRisk = Math.floor(Math.abs(profit)*100/item.num)
									// 选择最大risk
									if( tmpRisk > risk ){
										risk = tmpRisk
									}
								}
							}
						}
						that.total = total
						that.risk = risk
					}

					if(!that._isDestroyed){
						that.timer_data = setTimeout(function() {
							that.getData();
						}, that.timeout_get_data);
					}
				});
			},
			submit_order() {
				// post :pid, price_type, price, type, level, hand, amount, stop_win, stop_loss
				// check
				if (!this.order.hand) {
					this.$message(this.$t('请输入数量'));
					return
				}

				var postdata = {
					pid: this.id,
					price_type: this.order.price_type,
					price: this.order.price,
					type: this.order.type,
					level: this.order.level,
					hand: this.order_amount,
					amount: this.order.hand,
					stop_win: this.order.stop_win,
					stop_loss: this.order.stop_loss,
				};

				let that = this;
				this.loading = true;
				this.greq('post', '/api/contract_order', postdata, function(rs) {
					that.loading = false;
					if (rs.status) {
						that.$message(rs.msg);

						// clean form
						that.order.hand = '';
						that.order.stop_win = '';
						that.order.stop_loss = '';
					} else {
						that.gerror(rs.msg);
					}
				}, function(err) {
					that.loading = true;
					console.log(err);
				});
			},
			order_cancel(oid) {
				let that = this;
				this.greq('post', '/api/contract_cancel', {
					oid: oid
				}, function(rs) {
					if (rs.status) {
						that.$message(rs.msg);
					} else {
						that.gerror(rs.msg);
					}
				});
			},
			order_sell(oid) {
				let that = this;
				this.greq('post', '/api/contract_sell', {
					oid: oid
				}, function(rs) {
					if (rs.status) {
						that.$message(rs.msg);
					} else {
						that.gerror(rs.msg);
					}
				});
			}
		},
		mounted() {
			this.prefixSuffix();
			this.getData();
		},
		beforeDestroy() {
			console.log('before destroy');
			if (this.timer_data) {
				clearTimeout(this.timer_data);
				this.timer_data = false;
			}
		},
		destroyed(){
			console.log('after destroy');
			if (this.timer_data) {
				clearTimeout(this.timer_data);
				this.timer_data = false;
			}
		}
	}
</script>

<style>
	.contract_page .long_btn {
		padding: 8px 0px;
		background-color: #cd4e65;
		border-radius: 6px;
		color: #fff;
	}

	.contract_page .empty_btn {
		padding: 8px 0px;
		border-radius: 6px;
		color: #8b9eac;
	}

	.contract_page .short_btn {
		padding: 8px 0px;
		background-color: #1cad90;
		border-radius: 6px;
		color: #fff;
	}

	.contract_page .el-input__icon {
		font-style: normal;
	}

	.win_or_loss input {
		text-indent: 6px;
	}

	.contract_page .el-select-dropdown__item {
		font-size: 12px;
	}

	.contract_page .l10_bar {
		position: relative;
		height: 1px;
		width: 100%;
	}

	.contract_page .l10_bg {
		height: 18px;
		opacity: 0.4;
		position: absolute;
		right: 0px;
	}
	
	.contract_page .el-input--prefix .el-input__inner{
		padding-left: 60px;
	}
	
</style>
