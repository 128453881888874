<template>
	<div class="lang_page">
		<div class="bg_white">
			<el-row class="p2 c_gray" type="flex" align="middle">
				<el-col :span=4 class="c_blue tc" style="font-size:30px;">
					<router-link to="/" class="c_blue">
						<i class="fa fa-angle-left" aria-hidden="true"></i>
					</router-link>
				</el-col>
				<el-col :span=16 class="f16">
					{{$t("选择语言")}}
				</el-col>
				<el-col :span=4>&nbsp;</el-col>
			</el-row>
		</div>
		
		<div style="text-indent:20px;">
			<div v-for="row of langs" v-bind:key="row.id" class="mt2 p3 bg_white tl c_gray" @click="setlang(row.value)">
				<span :class="{'c_blue fbold':row.value==currentLang}">
					{{row.name}}	
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "LangView",
		data(){
			return {
				currentLang: this.$i18n.locale,
				langs: [
					{
						name: "English",
						value: 'en'
					},
					{
						name: "繁体中文",
						value: 'tw'
					},
					{
						name: "ไทย",
						value: 'th'
					},
					{
						name: "Français",
						value: 'fr'
					},
					{
						name: "Deutsch",
						value: 'de'
					},
					{
						name: "日本語",
						value: 'jp'
					},
					{
						name: "한국어",
						value: 'ko'
					},
					{
						name: "Español",
						value: 'es'
					},
					{
						name: "Tiếng Việt",
						value: 'vi'
					},
					{
						name: "português",
						value: 'pt'
					}
				]
			}
		},
		methods: {
			setlang: function(lang){
				localStorage.setItem('lang', lang)
				this.currentLang = lang
				
				// set i18n
				this.$parent.$i18n.locale = lang
				this.$router.push('/');
			}
		}
	}
</script>

<style>
</style>